import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SeO from "../components/seo"
import Header from "../components/header"
// import Button from "../components/button"

import { css } from "@emotion/react"
import Button from "../components/button"

const PageTemplate = (props) => {
    const lang = props.pageContext.lang
    const page = props.data.page
    const title = page.data.title.text
    const subtitle = page.data.subtitle.text
    const desc = page.data.description
    const body = page.data.body
    // const thumb_image = page.data.image

    return (
        <Layout>
            <SeO 
                title={title}
                description={desc}
                lang={lang}
                slug={props.uri}
            />

            <Header 
                title={title}
                subtitle={subtitle}
                intro={desc}
                lang={lang}
                // small
                // indent={thumb_image.fluid ? true : false}
            />

            <div className="bg--white">
                <article className="container" css={main}>
                    {body.map( slice => {

                        if ( slice.slice_type === 'rich_text' ) {
                            return <div 
                              dangerouslySetInnerHTML={{ __html: slice.primary.rich_text.html }} 
                              style={{marginBottom: "88px"}}
                            />
                        }

                        if ( slice.slice_type === 'blurb_columns' && slice.items ) {
                            return <div 
                              className={(slice.items.length % 2 === 0) ? "two-col-grid" : "three-col-grid" }
                              style={{marginBottom: "88px"}}
                            >
                                {slice.items.map( item => (
                                    <div css={blurbColumn}>
                                        <div className="blurb-column__img">
                                          <Img fluid={item.blurb_image.fluid} />
                                        </div>
                                        <h3>{item.blurb_title.text}</h3>
                                        <h4>{item.blurb_subtitle.text}</h4>
                                        <div dangerouslySetInnerHTML={{ __html: item.blurb_text.html }} 
                                          className="blurb-column__text"
                                        />
                                        {item.link_text &&
                                            <Button link={item.external_link.url} text={item.link_text} />
                                        }
                                    </div>
                                ))}
                            </div>
                        }

                        return null

                    })}
                </article>
            </div>

        </Layout>
    )
}

export default PageTemplate

const main = css`
    padding-top: 50px;
    padding-bottom: 50px;
`

const blurbColumn = css`
  text-align: center;

  .blurb-column__img {
    max-width: 300px;
    margin: 0 auto;
  }

  h3 {
    margin-top: 10px;
  }

  h4 {
    color: #888;
    font-weight: 500;
    margin-bottom: 10px;
  }

  ul {
    background-color: #F6F6F6;
    border-radius: 10px;
    padding: 15px 20px 5px;
    font-size: .9rem;
    color: #888;
    margin: 0 0 10px;
    
    li {
      list-style-type: none;
      margin-bottom: 10px;
    }
  }

  .blurb-column__text {
    text-align: justify;
  }
`

export const pageTemplateQuery = graphql`
    query PageTemplateQuery($slug: String!) {
        page: prismicPages(uid: {eq: $slug}) {
            uid
            lang
            data {
                title {
                    text
                }
                subtitle {
                    text
                }
                description
                body {
                    __typename
                    ... on PrismicPagesDataBodyRichText {
                      id
                      slice_type
                      primary {
                        rich_text {
                          html
                        }
                      }
                    }
                    ... on PrismicPagesDataBodyBlurbColumns {
                      id
                      slice_type
                      items {
                        blurb_title {
                          text
                        }
                        blurb_subtitle {
                          text
                        }
                        blurb_text {
                          html
                        }
                        blurb_image {
                          fluid {
                            ...GatsbyPrismicImageFluid
                          }
                        }
                        link_text
                        external_link {
                          target
                          url
                        }
                        internal_link {
                          url
                        }
                      }
                    }
                }
            }
        }
    }  
`